import React, { useContext } from 'react';
import * as S from './QuizText.styles';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const QuizText = props => {
  const { text, checkQueryParams = false } = props;
  const { answers } = useContext(FunnelContext);
  const [answers2] = useLocalStorage('answers', {});

  const FinalText = () => {
    let finalText = text;
    if (checkQueryParams) {
      const params = new URLSearchParams(window.location.search);
      for (const [key, value] of params.entries()) {
        finalText = finalText?.replace(new RegExp(`{${key}}`, 'g'), value);
      }
      return finalText;
    }
    if (!answers['breed-label']) {
      answers['breed-label'] = 'dog';
    }
    for (let key in answers) {
      if (key === 'breed-label') {
        answers[key] = answers[key].length ? answers[key] : 'dog';
      }
      finalText = finalText?.replace(new RegExp(`{${key}}`, 'g'), answers[key]);
    }

    for (let key2 in answers2) {
      if (key2 === 'breed-label') {
        answers2[key2] = answers2[key2].length ? answers2[key2] : 'dog';
      }
      finalText = finalText?.replace(
        new RegExp(`{${key2}}`, 'g'),
        answers2[key2]
      );
    }
    return finalText;
  };

  return (
    <S.Container
      dangerouslySetInnerHTML={{ __html: FinalText() || `--empty text--` }}
    ></S.Container>
  );
};

export default QuizText;
